<template>
  <v-card tile :elevation="0" class="crud-box list-orders px-4">
    <h1>Manage Revenue</h1>
    <v-layout wrap class="pt-4">
      <v-btn color="primary" outlined class="mr-2" @click="onClickExport">
        <v-icon>mdi-database-export</v-icon>
        Export
      </v-btn>
    </v-layout>
    <v-layout wrap class="px-4 pt-4">
      <v-layout
        wrap
        class="search-fields search-fields-page"
      >
        <v-text-field
          dense
          outlined
          @keyup.enter="doSearch"
          class="mr-2"
          label="Search Order Name"
          v-model="searchFields.name.value"
        />
        <v-text-field
          dense
          outlined
          @keyup.enter="doSearch"
          class="mr-2"
          label="Search Order Code"
          v-model="searchFields.code.value"
        />
        <v-text-field
          dense
          outlined
          @keyup.enter="doSearch"
          class="mr-2"
          label="Search Tracking Number"
          v-model="searchFields.tracking_number.value"
        />
        <v-menu
          v-model="showCreatedDatePicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Ngày tạo order"
              class="mr-2"
              clearable
              dense
              outlined
              readonly
              v-on="on"
              v-bind="attrs"
              :value="DateRangeText(createdDates)"
              @click="showCreatedDatePicker = true"
            ></v-text-field>
          </template>
          <v-date-picker v-model="createdDates" range></v-date-picker>
        </v-menu>
        <v-spacer style="flex-grow: 100000000000000 !important"></v-spacer>
      </v-layout>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card
      tile
      :elevation="1"
      :disabled="tblLoading"
      outlined
      class="block-tbl mx-4"
    >
      <v-data-table
        :headers="tblHeader"
        mobile-breakpoint="0"
        :items="listItems"
        :server-items-length="tblPagination.total"
        :loading="tblLoading"
        :footer-props="tblConfig.footerProps"
        @dblclick:row="onClickRow"
        :options.sync="tblOptions"
        :loading-text="tblConfig.text.loading"
        v-model="pickedItems"
        :show-select="tblConfig.showSelect"
      >
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{ item }">
          {{ getIndexOfItem(item) }}
        </template>

        <template v-slot:item.name="{ item }">
          <v-tooltip bottom v-if="item.note">
            <template v-slot:activator="{ on, attrs }">
              <div class="block-item-name" v-bind="attrs" v-on="on">
                <div class="item-name">{{ item.name }}</div>
                <div class="item-code">{{ item.code }}</div>
              </div>
            </template>
            <span>{{ item.note }}</span>
          </v-tooltip>
          <template v-else>
            <div class="block-item-name">
              <div class="item-name">{{ item.name }}</div>
              <div class="item-code">{{ item.code }}</div>
            </div>
          </template>
        </template>

        <template v-slot:item.service="{ item }">
          <v-tooltip bottom v-if="item.employee_note">
            <template v-slot:activator="{ on, attrs }">
              <div class="item-service-name" v-bind="attrs" v-on="on">
                {{ serviceGetName(item.service_id) }}
              </div>
            </template>
            <span>{{ item.employee_note }}</span>
          </v-tooltip>
          <template v-else>
            {{ serviceGetName(item.service_id) }}
          </template>
        </template>
        <template v-slot:item.paid_fee="{ item }">
          <money-format
            :value="getPaidFee(item)"
            style="display: inline-block"
            :hide-subunits="true"
            :locale="'vi'"
            :currency-code="'VND'"
          ></money-format>
        </template>
        <template v-slot:item.remain_fee="{ item }">
          <money-format
            :value="getRemainFee(item)"
            style="display: inline-block"
            :hide-subunits="true"
            :locale="'vi'"
            :currency-code="'VND'"
          ></money-format>
        </template>
        <template v-slot:item.total_fee="{ item }">
          <money-format
            :value="getTotalFee(item)"
            style="display: inline-block"
            :hide-subunits="true"
            :locale="'vi'"
            :currency-code="'VND'"
          ></money-format>
        </template>
        <template v-slot:item.status="{ item }">
          <div
            v-html="orderGetStatusText(item.status).replace(' - ', '<br>')"
          ></div>
        </template>

        <template v-slot:item.buy_tracking_status="{ item }">
          <span
            :class="{
              'green--text':
                item.buy_tracking_status ===
                CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_SUCCESS,
              'red--text':
                item.buy_tracking_status ===
                CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_FAIL,
            }"
          >
            {{ buyTrackingGetStatusText(item.buy_tracking_status) }}
          </span>
        </template>

        <template v-slot:item.departed_date="{ item }">
          {{ departedDate(item) }}
        </template>

        <template v-slot:item.delivered_date="{ item }">
          {{ deliveredDate(item) }}
        </template>
        <template v-slot:item.customer_email="{ item }">
          {{ item.customer.email }}
        </template>

        <template v-slot:item.actions="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="onClickDetail(props)"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                color="primary"
              >
                mdi-view-list
              </v-icon>
            </template>
            <span>Chi tiết đơn hàng</span>
          </v-tooltip>

          <v-tooltip bottom v-if="canEditOrder(props)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="onClickEdit(props)"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                color="success"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Sửa đổi thông tin</span>
          </v-tooltip>
          <v-tooltip bottom v-if="needPayOrder(props)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="onClickPayOrder(props)"
                v-bind="attrs"
                v-on="on"
                color="error"
                >mdi-currency-usd
              </v-icon>
            </template>
            <span>Thanh toán</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-layout wrap class="px-4">
      <div
        style="
          display: flex;
          align-items: center;
          margin-right: 1rem;
          font-weight: bold;
        "
      >
        <label>Search Fields:</label>
      </div>
      <template v-for="(field, idx) in toggleableSearchFields">
        <v-checkbox
          v-if="
            !searchFields[field].display_role ||
            searchFields[field].display_role.indexOf(UserRole) > -1
          "
          v-model="searchFields[field].display"
          :key="idx"
          style="margin-left: 1rem"
        >
          <template v-slot:label>
            {{ searchFields[field].display_name }}
          </template>
        </v-checkbox>
      </template>
    </v-layout>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import indexMixin from "./indexMixin";
import MoneyFormat from "vue-money-format";

export default {
  name: "ManageRevenue",
  components: { MoneyFormat },
  mixins: [indexMixin],
  data: () => ({
    searchFields: {
      name: {
        value: "",
        type: "like",
        display: false,
        display_name: "Order Name",
      },
      code: {
        value: "",
        type: "like",
        display: true,
        display_name: "Order Code",
      },
      tracking_number: {
        value: "",
        type: "like",
        display: false,
        display_name: "Tracking Number",
      },
      created_date_from: {
        value: "",
        type: "=",
        display: false,
        force_hidden: true,
      },
      created_date_to: {
        value: "",
        type: "=",
        display: false,
        force_hidden: true,
      },
    },
    searchWith:
      "customer;orderItems;bigContainer;orderFees;payments",
    tblHeader: [
      {
        text: "No",
        value: "no",
        sortable: false,
        groupable: false,
        width: "55px",
        align: "start",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Service",
        value: "service",
      },
      {
        text: "Receiver Country",
        value: "receiver_country.country_code",
        sortable: false,
      },
      {
        text: "Buy Tracking Status",
        value: "buy_tracking_status",
        sortable: false,
      },
      {
        text: "Tracking Number",
        value: "tracking_number",
        sortable: false,
      },
      {
        text: "Total Fee",
        value: "total_fee",
      },
      {
        text: "Paid Fee",
        value: "paid_fee",
      },
      {
        text: "Remain Fee",
        value: "remain_fee",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        width: "155px",
      },
      {
        text: "Departed Date",
        value: "departed_date",
      },
      {
        text: "Delivered Date",
        value: "delivered_date",
      },
      {
        text: "Customer email",
        value: "customer_email",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        groupable: false,
        width: "111px",
        align: "center",
      },
    ],
    showCreatedDatePicker: false,
    createdDates: [],
    buyTrackingDates: [],
    idsToPay: [],
    isCopyOrder: false,
  }),
  computed: {
    ...mapGetters({
      items: "order/all",
      tblPagination: "order/pagination",
      services: "service/all",
      serviceGetName: "service/serviceName",
      listOrderStatus: "order/listOrderStatus",
      orderGetStatusText: "order/getStatusText",
      buyTrackingGetStatusText: "order/buyTrackingGetStatusText",
      listBuyTrackingStatus: "order/listBuyTrackingStatus",
    }),
    listServices() {
      let listItems = [{ text: "All Service", value: "" }];
      this.services.forEach((r) => {
        listItems.push({
          text: r.name,
          value: r.id,
        });
      });
      return listItems;
    },
    DateRangeText() {
      return (dateArr) => {
        return dateArr.join(" ~ ");
      };
    },
    departedDate() {
      return (item) => {
        try {
          return item.departed_date.substr(0, 10);
        } catch (e) {
          return "";
        }
      };
    },
    deliveredDate() {
      return (item) => {
        try {
          return item.delivered_date.substr(0, 10);
        } catch (e) {
          return "";
        }
      };
    },
    canPayMultiOrders() {
      return (
        this.pickedItems.filter((item) => {
          return (
            item.status !== this.CONSTANTS.ORDER_STATUS.NEW_WAIT_CONF &&
            item.status !== this.CONSTANTS.ORDER_STATUS.UPDATE_WAIT_CONF
          );
        }).length === 0
      );
    },
    canBuyTracking() {
      return (
        this.pickedItems.filter((item) => {
          return (
            item.buy_tracking_status ===
              this.CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_SUCCESS ||
            item.buy_tracking_status ===
              this.CONSTANTS.TRACKING_NUMBER_BUY_STATUS.WAIT_BUY
          );
        }).length === 0
      );
    },

    getPaidFee() {
      return (order) => {
        let payments = order.payments.filter(
          (p) =>
            p.status === this.CONSTANTS.PAYMENT_STATUS.PAID &&
            p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER
        );
        let paidFee = 0;
        payments.forEach((p) => (paidFee += parseFloat(p.amount)));
        return paidFee;
      };
    },
    getTotalFee() {
      return (order) => {
        let totalFee = 0;
        order.order_fees.forEach((f) => (totalFee += parseFloat(f.fee_amount)));
        return parseInt(totalFee);
      };
    },
    getRemainFee() {
      return (order) => {
        let payments = order.payments.filter(
          (p) =>
            p.status === this.CONSTANTS.PAYMENT_STATUS.PAID &&
            p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER
        );
        let paidFee = 0;
        payments.forEach((p) => (paidFee += parseFloat(p.amount)));
        let totalFee = this.getTotalFee(order);
        return totalFee - paidFee;
      };
    },
    toggleableSearchFields() {
      return Object.keys(this.searchFields).filter(
        (sf) => this.searchFields[sf].force_hidden !== true
      );
    },
  },
  methods: {
    ...mapActions({
      getListItems: "order/get",
      exportListItems: "order/export",
      requestBuyTrackingNumber: "order/requestBuyTrackingNumber",
    }),
    onClickPayOrder(props) {
      this.dialog.payOrders = true;
      this.idsToPay = [props.item.id];
    },
  },
  watch: {
    createdDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0])))
          this.searchFields.created_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1])))
          this.searchFields.created_date_to.value = val[1];
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.list-orders::v-deep {
  .v-data-table-header th {
    white-space: nowrap;
  }
}

.block-item-name {
  .item-name {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .item-code {
    font-size: 0.8rem;
  }
}

.item-service-name {
  white-space: nowrap;
}
</style>
